import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { useAppStore } from '@stores/app';
import { useAutoInvestStore } from '@stores/auto-invest';
import { useInvestmentEntityStore } from '@stores/investment-entity';

const autoInvestEnableFlowRouteGuard: NavigationGuard = async (to, from, next) => {
	const investmentEntityStore = useInvestmentEntityStore();
	if (investmentEntityStore.hasActiveRecurringInvestmentSchedule) {
		next({ name: 'settings-auto-invest-view' });
	} else if (!investmentEntityStore.eligibleForRecurringInvestment) {
		const appStore = useAppStore();
		next({ name: 'account-overview', replace: true });
		appStore.addToast({
			type: 'error',
			message: 'Your investment account is not eligible for Auto-Invest.'
		});
	} else {
		const plaidRedirect = to.query['oauth_state_id'];

		if (plaidRedirect) {
			next({ query: { oauth_state_id: plaidRedirect } });
		} else {
			next();
		}
	}
};

const sameAutoInvestSessionCheck: NavigationGuard = async (to, from, next) => {
	const autoInvestStore = useAutoInvestStore();
	const investmentEntityStore = useInvestmentEntityStore();
	if (investmentEntityStore.hasActiveRecurringInvestmentSchedule) {
		next({ name: 'settings-auto-invest-view' });
	} else if (!autoInvestStore.autoInvestSchedule) {
		next({ name: 'auto-invest-enable-new' });
	} else {
		next();
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const autoInvestEnableRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/auto-invest`,
		name: 'auto-invest-enable',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: autoInvestEnableFlowRouteGuard,
		component: () => import('@views/auto-invest/auto-invest-enable.vue'),
		redirect: { name: 'auto-invest-enable-new' },
		children: [
			{
				path: `new`,
				name: 'auto-invest-enable-new',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 1 },
				beforeEnter: async (to, from, next) => {
					const investmentEntityStore = useInvestmentEntityStore();
					if (investmentEntityStore.hasActiveRecurringInvestmentSchedule) {
						next({ name: 'settings-auto-invest-view' });
					} else {
						const autoInvestStore = useAutoInvestStore();
						await Promise.all([
							investmentEntityStore.storeBankAccounts(),
							autoInvestStore.fetchFrequenciesAndAmounts(),
							autoInvestStore.fetchAgreements()
						]);

						const plaidRedirect = to.query['oauth_state_id'];

						if (plaidRedirect) {
							next({ query: { oauth_state_id: plaidRedirect } });
						} else {
							next();
						}
					}
				},
				component: () => import('@views/auto-invest/auto-invest-enable-new.vue')
			},
			{
				path: `agreements`,
				name: 'auto-invest-enable-agreements',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 2 },
				beforeEnter: sameAutoInvestSessionCheck,
				component: () => import('@views/auto-invest/auto-invest-enable-agreements.vue')
			},
			{
				path: `success`,
				name: 'auto-invest-enable-success',
				meta: { minTokenScopeRequired: 'FULL_ACCESS', step: 3 },
				beforeEnter: async (to, from, next) => {
					const investmentEntityStore = useInvestmentEntityStore();
					if (investmentEntityStore.autoInvestSchedule) {
						next();
					} else {
						next('auto-invest-enable-new');
					}
				},
				component: () => import('@views/auto-invest/auto-invest-enable-success.vue')
			}
		]
	},
	{
		path: '/account/recurring-investment/new',
		name: 'recurring-investment-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'auto-invest-enable-new', replace: true }
	}
];

export default autoInvestEnableRoutes;
