import './styles/master.scss';
import '@yzfe/svgicon/lib/svgicon.css';
import 'intersection-observer';
import * as Sentry from '@sentry/vue';
import {
	datadogRum,
	DefaultPrivacyLevel,
	RumActionEventDomainContext,
	RumErrorEvent,
	RumErrorEventDomainContext
} from '@datadog/browser-rum';
import { denyUrls, ignoreErrors } from '@utils/error-tracking';
import { handleDatadogActionBeforeSend, handleDatadogErrorBeforeSend, processDatadogErrorEvent } from '@utils/datadog';
import AnimationRevealDirective from './directives/reveal';
import App from './App.vue';
import ClickAwayDirective from './directives/click-away';
import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import { createPinia } from 'pinia';
import { getDatadogSampleRate } from '@utils/analytics';
import LazyLoadDirective from './directives/lazy-src';
import { registerBaseComponents } from '@components/_globals';
import router from './router';
import { sentryBeforeSendHandler } from '@utils/sentry';
import { sessionTimingIntegration } from '@sentry/integrations';
import { vMaska } from 'maska/vue';
import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';

/*eslint-disable @typescript-eslint/no-explicit-any */
declare global {
	interface Window {
		AppleID: any;
		AwsWafCaptcha: any;
		AwsWafIntegration: any;
		awsWafCookieDomainList: string[];
		Persona: any;
		Intercom: any;
		Calendly: any;
		dataLayer: any;
		fbq: any;
		ga: any;
		google: any;
		gtag: any;
		hj: any;
		letterpress: any;
		pdst: any;
		prerenderReady: boolean;
		twttr: any;
		uetq: any;
		Plaid: any;
		initMap: any;
		SENTRY_RELEASE: { id: string }; // auto-injected by Sentry webpack plugin
	}
}
/*eslint-enable @typescript-eslint/no-explicit-any */

const app = createApp(App);

registerBaseComponents({ app });

const head = createHead();
app.use(head);

app.use(VueSvgIconPlugin, {
	tagName: 'SvgIcon',
	isOriginalDefault: true
});

app.directive('reveal', AnimationRevealDirective);
app.directive('lazy', LazyLoadDirective);
app.directive('click-away', ClickAwayDirective);
app.directive('maska', vMaska);

const pinia = createPinia();
app.use(pinia);

if (import.meta.env.VITE_DATADOG_RUM_ENABLED === 'true') {
	const { sessionSampleRate, sessionReplaySampleRate } = getDatadogSampleRate();
	datadogRum.init({
		applicationId: `${import.meta.env.VITE_DATADOG_RUM_APP_ID}`,
		clientToken: `${import.meta.env.VITE_DATADOG_RUM_APP_TOKEN}`,
		site: 'datadoghq.com',
		service: 'fundrise-web',
		version: window.SENTRY_RELEASE?.id ?? undefined,
		env: `${import.meta.env.VITE_DATADOG_RUM_ENVIRONMENT}`,
		sessionSampleRate,
		sessionReplaySampleRate,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: `${import.meta.env.VITE_DATADOG_RUM_PRIVACY}` as DefaultPrivacyLevel,
		enableExperimentalFeatures: ['feature_flags'],
		beforeSend: (event, context): boolean => {
			if (event.type === 'action') {
				return handleDatadogActionBeforeSend(event, context as RumActionEventDomainContext);
			}

			if (event.type === 'error') {
				event = processDatadogErrorEvent(event, context as RumErrorEventDomainContext);
				return handleDatadogErrorBeforeSend(event as RumErrorEvent, context as RumErrorEventDomainContext);
			}

			return true;
		}
	});

	datadogRum.startSessionReplayRecording();
}

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
	Sentry.init({
		app,
		dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
		environment: `${import.meta.env.VITE_SENTRY_ENVIRONMENT}`,
		attachProps: false,
		trackComponents: true,
		logErrors: true,
		integrations: [sessionTimingIntegration()],
		beforeSend: (event, hint) => sentryBeforeSendHandler(event, hint),
		ignoreErrors,
		denyUrls
	});
	Sentry.setTag('Application', 'Vue');
}

if (import.meta.env.VITE_WAF_CHALLENGE_URL && import.meta.env.VITE_BASE_URL) {
	const cookieDomain = new URL(import.meta.env.VITE_BASE_URL).hostname;
	window.awsWafCookieDomainList = [`.${cookieDomain}`];
}

app.use(router);

app.mount('#app');
