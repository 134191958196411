import {
	AccountChecklistDetails,
	AccountDocumentItem,
	AccountDocumentMonthlyAccountStatement,
	AccountDocumentsData,
	DashboardData,
	DashboardModuleData,
	DashboardModuleName,
	DashboardMultiItemType,
	DashboardNewsFeed,
	DividendsModuleData,
	InvestOtherActionsToDisplay,
	NewsFeedItem,
	ReinvestmentConfirmation
} from 'types/account';
import { AccountClosureCompletionAction, AccountClosureEligibility } from 'types/account/account-closure';
import { apiSecureRequest, investmentEntityRequest } from '@utils/request';
import { getPublicNewsFeedByPageNumber } from '@api/investment-entity';

export const getAccountDocuments = async (): Promise<AccountDocumentsData> => {
	const response = await investmentEntityRequest.get(`/account/account-documents`);
	return response.data;
};

export const getMonthlyAccountStatement = async (
	yearMonthDay: string
): Promise<AccountDocumentMonthlyAccountStatement> => {
	const response = await investmentEntityRequest.get(`/monthly-account-statement`, {
		params: { yearMonthDay },
		suppressToast: () => true
	});
	return response.data;
};

export const getReinvestmentConfirmations = async (
	quarter: string,
	year: string
): Promise<Array<ReinvestmentConfirmation>> => {
	const response = await investmentEntityRequest.get(
		`/account/reinvestment/confirmation?year=${year}&quarter=${quarter}`,
		{
			suppressToast: () => true
		}
	);
	return response.data;
};

export const getDashboardNewsFeed = async (): Promise<DashboardNewsFeed> => {
	const response = await investmentEntityRequest.get(`/dashboard/news-feed`);
	return response.data;
};

export const getPublicDashboardNewsFeed = async (): Promise<Array<NewsFeedItem>> => {
	const apiParams = {
		limit: '3'
	};
	const modularNewsFeedResponse = await getPublicNewsFeedByPageNumber(apiParams);

	return modularNewsFeedResponse.items;
};

export const getDashboardData = async (): Promise<DashboardData> => {
	const response = await investmentEntityRequest.get(`/dashboard`);
	return response.data;
};

export const getDividendsModuleData = async (): Promise<DividendsModuleData> => {
	const response = await investmentEntityRequest.get(`/dashboard/dividends`, {
		suppressToast: () => true
	});
	return response.data;
};

export const getUserOverviewModules = async (): Promise<DashboardModuleData> => {
	const response = await investmentEntityRequest.get(`/user-overview-modules`);
	return response.data;
};

export const dismissDashboardModule = async (
	module: DashboardModuleName,
	multiItem?: DashboardMultiItemType
): Promise<void> => {
	await investmentEntityRequest.post(`/dashboard-modules/dismiss`, { module, multiItem });
};

export const getTaxDocuments = async (taxYear: string): Promise<AccountDocumentItem[]> => {
	const response = await investmentEntityRequest.post(`/account/tax-documents`, { taxYear });
	return response.data;
};

export const viewedUpdatedAddressModal = async (): Promise<void> => {
	await investmentEntityRequest.post('/viewed-update-address-modal');
};

export const getAccountChecklistData = async (): Promise<AccountChecklistDetails> => {
	const response = await investmentEntityRequest.get(`/next-checklist`);
	return response.data;
};

export const getOtherActionsData = async (): Promise<InvestOtherActionsToDisplay> => {
	const response = await investmentEntityRequest.get('/invest/other-actions');
	return response.data;
};

export const getAccountClosureEligibility = async (): Promise<AccountClosureEligibility> => {
	const response = await apiSecureRequest.get('/account/deletion/initiate');
	return response.data;
};

export const submitAccountClosure = async (): Promise<AccountClosureCompletionAction> => {
	const response = await apiSecureRequest.put('/account/deletion/complete');
	return {
		completionAction: response.data.completionAction,
		scheduledAccountClosureDate: response.data.scheduledAccountClosureDate ?? null
	};
};
